import { useSegments } from '../../hooks';
import Icon from '@dx-ui/osc-icon';
import { Card } from './CardGroup/Card';
import { creditCardTypes, getFilteredSegmentCrediCardContent } from './helpers';

import type {
  OffersCreditCard,
  OffersCreditCardBuiltinBenefitsBlock,
  OffersCreditCardGroup,
} from '@dx-ui/gql-types';
type Props = {
  cardType: string;
  cards: OffersCreditCardGroup[];
};

type BenefitsProps = {
  builtInBenefits: OffersCreditCardBuiltinBenefitsBlock[];
};

export const BuiltInBenefits = ({ cards, cardType }: Props) => {
  const segments = useSegments();
  return cards?.map((card, index) => {
    const creditCardContent: OffersCreditCard = getFilteredSegmentCrediCardContent(card, segments);
    return (
      <Card
        key={`${cardType}-cards-built-in-benefits-${card?.cardName}`}
        card={creditCardContent}
        actionDetail={`compare:benefits:apply:${
          cardType === 'personal' ? creditCardTypes[index] : creditCardTypes[3]
        }`}
      >
        <BuiltInDetails builtInBenefits={creditCardContent?.builtinBenefitsBlocks} />
      </Card>
    );
  });
};

const BuiltInDetails = ({ builtInBenefits }: BenefitsProps) => {
  return (
    <div className="text-text mt-6 w-80 max-w-xs sm:w-auto">
      <div className="mb-6">
        <h6 className="text-xl font-bold">{builtInBenefits[0]?.heading}</h6>
        {builtInBenefits[1]?.description ? (
          <p className="text-sm font-normal">{builtInBenefits[1]?.description} </p>
        ) : null}
      </div>
      <ul className="text-left text-lg rtl:text-right">
        {builtInBenefits[2]?.unorderedList?.map((benefit) => (
          <li key={benefit} className="relative mb-6 flex font-normal leading-6 ">
            <Icon
              name="checkmark-heavy"
              variant="solid"
              size="sm"
              className="fill-primary absolute ml-1 mt-1"
            />
            <span className="ltr:pl-7 rtl:pr-7">{benefit}</span>
          </li>
        ))}
      </ul>
      {builtInBenefits[3]?.heading ? (
        <div className="border-text-alt mx-auto w-10/12 rounded-md border p-2 px-3 text-left text-lg rtl:text-right">
          <p className="mb-4 text-lg font-bold">{builtInBenefits[3]?.heading}</p>
          <ul>
            {builtInBenefits[4]?.unorderedList?.map((spendBenefit) => (
              <li key={spendBenefit} className="relative mb-8 flex font-normal leading-4">
                <Icon
                  name="checkmark-heavy"
                  variant="solid"
                  size="sm"
                  className="fill-success absolute ml-1"
                />
                <span className="ltr:pl-7 rtl:pr-5">{spendBenefit}</span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
