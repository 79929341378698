/* eslint-disable no-restricted-globals */
const hostPattern = /hilton.com/;

export const isValidHost = (host: string) => hostPattern.test(host);
export const isRelative = (url: string) =>
  (url && typeof url === 'string' && url.startsWith('/')) || false;
export const buildForwardUrl = (relativeUrl: string) => `${location.origin}${relativeUrl}`;

const isValidForwardUri = (forwardPageURI: string) => {
  if (!forwardPageURI) {
    return false;
  }
  if (isRelative(forwardPageURI)) {
    return true;
  }
  try {
    const forwardURL = new URL(forwardPageURI);
    return isValidHost(forwardURL.host);
  } catch {
    return false;
  }
};

export default isValidForwardUri;
