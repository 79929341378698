import { auth } from '../../utils/auth';
import { GLOBAL_RESOURCE_BUNDLES } from '../../utils/constants/global-resource-bundles';
import { show404NonTransPage } from '../../utils/helpers/show404NonTransPage';

import { makeServerQueryClient } from '@dx-ui/framework-react-query';
import { REACT_QUERY_CONFIG } from '../../config/react-query';
import type { BrandQuery, OffersCreditCardGroup } from '../../types/generated/types';

import Markdown from 'markdown-to-jsx';
import { Head } from '../../components/Head/Head';
import { Link } from '@dx-ui/osc-link';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Spinner } from '@dx-ui/osc-spinner';
import {
  CardBreakdown,
  CardComparison,
  CardDisplay,
  InternationalCards,
} from '../../components/CreditCardsV2';
import { useLanguage, useHasMounted, useSegments } from '../../hooks';
import {
  useCreditCardOffersQuery,
  useCmsPolicyQuery,
  useBrandQuery,
} from '../../queries/generated/react-query';
import { isValidHost } from '../../utils/helpers/forwardUri';
import { getUrlHost } from '../../utils/helpers/urlBuilder';
import cx from 'classnames';
import { BrandThemeWrapper } from '@dx-ui/osc-marketing';
import { serverSideProps } from '../../utils/helpers/serverSideProps';

const CreditCards: NextPage<typeof getServerSideProps> = ({ configRules }) => {
  const [t] = useTranslation('credit-cards');
  const language = useLanguage();
  const hasMounted = useHasMounted();
  const segments = useSegments();

  const { data: creditCardOffersData, isLoading } = useCreditCardOffersQuery({ language });
  const { data: disclaimerContent } = useCmsPolicyQuery({
    language,
    policy: 'creditCardsDisclaimer',
  });
  const { data: fullWidthImageData } = useBrandQuery({
    language,
    brandCode: 'HH',
    path: 'credit-cards',
  });

  const markdownProps = useMemo(
    () => ({
      language,
      rule: configRules?.[0],
      origin: getUrlHost(),
    }),
    [configRules, language]
  );

  const filteredItems = useMemo(
    () =>
      fullWidthImageData?.brand?.page?.fullWidthImage?.reduce((acc, item) => {
        if (item?.segmentIds?.some((segmentId) => segments.includes(segmentId))) {
          acc.push({ ...item });
        }
        return acc;
      }, [] as NonNullable<NonNullable<BrandQuery['brand']>['page']>['fullWidthImage']),
    [fullWidthImageData?.brand?.page?.fullWidthImage, segments]
  );
  const imageHeadlinerFmt = filteredItems?.[0];
  const imageUrl = imageHeadlinerFmt?.imageCompound?.image?.variants?.find(
    (v) => v.size === 'md'
  )?.url;

  const internationalCreditCrads = creditCardOffersData?.creditCardOffers?.filter(
    (card) => card?.cardType === 'international'
  )[0];

  const viewDetailsLink = imageHeadlinerFmt?.link?.url
    ? imageHeadlinerFmt?.link?.url
    : (fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.link?.url as string);

  const viewDetailsURL = isValidHost(viewDetailsLink)
    ? `#${viewDetailsLink?.split('#')?.[1]}`
    : viewDetailsLink;

  if (isLoading) {
    return <Spinner size="xl" />;
  }
  return (
    <BrandThemeWrapper brandCodeForTheme="cb">
      <>
        <Head
          title={t('pageTitle')}
          keywords={t('metaData.keywords')}
          description={t('metaData.description')}
        />
        <section
          className="bg-cover bg-center"
          style={{
            backgroundImage: imageUrl
              ? `url("${imageUrl}")`
              : `url("${
                  fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.imageCompound?.image?.variants?.find(
                    (v) => v.size === 'md'
                  )?.url
                }")`,
          }}
        >
          <div className="flex h-[400px] w-full flex-col backdrop-brightness-75 sm:h-[700px]">
            <div className="bg-bg-inverse text-text-inverse order-last mt-auto w-full lg:order-none lg:mt-0">
              <p className="container py-1">
                {imageHeadlinerFmt?.caption
                  ? imageHeadlinerFmt?.caption
                  : fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.caption}
              </p>
            </div>
            <div className="container mx-auto max-w-screen-xl p-4 pt-10 sm:mx-auto">
              <p className="bg-bg text-primary border-primary my-4 max-w-fit shrink-0  rounded-full border-2 px-4 py-1 font-extrabold drop-shadow-md">
                {imageHeadlinerFmt?.attributes?.[0] ? (
                  <Trans t={t}>{imageHeadlinerFmt?.attributes?.[0]}</Trans>
                ) : (
                  <Trans t={t}>
                    {fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.attributes?.[0]}
                  </Trans>
                )}
              </p>
              <div className="max-w-max">
                <h1 className="text-text-inverse text-2xl font-bold drop-shadow-md sm:text-5xl">
                  {imageHeadlinerFmt?.headline
                    ? imageHeadlinerFmt?.headline
                    : fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.headline}
                </h1>
                <p className="text-text-inverse font-headline text-xl font-bold drop-shadow-md sm:mt-4 sm:text-4xl">
                  {imageHeadlinerFmt?.shortDescription
                    ? imageHeadlinerFmt?.shortDescription
                    : fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.shortDescription}
                  <span
                    className={cx('block font-sans text-base', {
                      'text-right md:-mt-4':
                        !fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.shortDescription,
                      'ml-4 inline-block font-normal':
                        fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.shortDescription,
                    })}
                  >
                    {imageHeadlinerFmt?.description
                      ? imageHeadlinerFmt?.description
                      : (fullWidthImageData?.brand?.page?.fullWidthImage?.[0]
                          ?.description as string)}
                  </span>
                </p>
                <Link
                  url={viewDetailsURL}
                  className="btn text-text-inverse border-text-inverse bg-primary mt-8 border-2 py-2 font-extrabold"
                  underline={false}
                >
                  {imageHeadlinerFmt?.link?.label
                    ? imageHeadlinerFmt?.link?.label
                    : fullWidthImageData?.brand?.page?.fullWidthImage?.[0]?.link?.label}
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="hidden lg:block">
          <div className="flex w-full flex-col">
            <div className="from-secondary-alt to-secondary-alt mx-auto -mb-64  max-w-screen-xl -translate-y-1/2 bg-gradient-to-b from-0% to-100% p-8 xl:-mb-60">
              <h2 className="text-secondary text-2xl font-extrabold">{t('cardDisplayTitle')}</h2>
              <CardDisplay />
            </div>
          </div>
        </section>

        {/* Mobile & Tablet View */}
        <div className="from-secondary-alt to-secondary-alt mx-auto block max-w-screen-xl bg-gradient-to-b from-0% to-100% p-4 lg:hidden">
          <h2 className="text-secondary text-2xl font-extrabold">{t('cardDisplayTitle')}</h2>
          <CardDisplay />
        </div>

        <section className="container">
          <h2 className="font-headline text-hilton my-4 text-center text-3xl font-bold sm:text-left rtl:sm:text-right">
            {t('headline')}
          </h2>
          <p className="mb-6 text-base">{t('description')}</p>
          <CardBreakdown />
          {hasMounted ? <CardComparison /> : null}
          <h2 className="font-headline text-hilton mb-8 mt-16 text-center text-2xl font-bold tracking-wide sm:text-left rtl:sm:text-right">
            {disclaimerContent?.policy?.content?.[0]?.heading ? (
              <Markdown {...markdownProps}>
                {disclaimerContent?.policy?.content?.[0]?.heading}
              </Markdown>
            ) : null}
          </h2>
          {disclaimerContent?.policy?.content?.map(({ description: disclaimerContent, _id }) =>
            disclaimerContent ? (
              <p className="mb-6 last:mb-32" key={`disclaimer-${_id}`}>
                {disclaimerContent}
              </p>
            ) : null
          )}
          {internationalCreditCrads && internationalCreditCrads?.items?.length > 0 ? (
            <InternationalCards
              internationalCardsContent={internationalCreditCrads as OffersCreditCardGroup}
            />
          ) : null}
        </section>
      </>
    </BrandThemeWrapper>
  );
};

export const getServerSideProps = (async (context) => {
  const { req, res, locale } = context;
  const authClient = auth(req as unknown as Request);
  const queryClient = makeServerQueryClient({
    ...REACT_QUERY_CONFIG,
    serverResponse: res,
    authClient,
  });

  const show404 = await show404NonTransPage({ url: req.path, language: locale, queryClient });
  if (show404) return { notFound: true };

  return serverSideProps({
    authClient,
    context,
    queryClient,
    pageProps: {
      metrics: {
        trackingData: {
          pageView: 'honorsPageView',
          pageData: {
            pageName: 'Credit Cards',
            expType: 'New Hilton',
            subSection: 'Brand',
            subSubSection: '',
            pageType: 'Credit Cards',
          },
        },
      },
    },
    namespaces: [...GLOBAL_RESOURCE_BUNDLES, 'credit-cards'],
    includeConfigRules: true,
  });
}) satisfies GetServerSideProps;

export default CreditCards;
