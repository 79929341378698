import { useSegments } from '../../hooks';
import { Card } from './CardGroup/Card';
import { creditCardTypes, getFilteredSegmentCrediCardContent } from './helpers';

import type {
  OffersCreditCardGroup,
  OffersCreditCardEarningPointsBlock,
  OffersCreditCard,
} from '@dx-ui/gql-types';
type Props = {
  cardType: string;
  cards: OffersCreditCardGroup[];
};

type DetailProps = {
  earningDetails: OffersCreditCardEarningPointsBlock[];
};

export const EarningPoints = ({ cards, cardType }: Props) => {
  const segments = useSegments();

  return cards?.map((card, index) => {
    const creditCardContent: OffersCreditCard = getFilteredSegmentCrediCardContent(card, segments);

    return (
      <Card
        key={`${cardType}-cards-earning-points-${card?.cardName}`}
        card={creditCardContent}
        actionDetail={`compare:earning:apply:${
          cardType === 'personal' ? creditCardTypes[index] : creditCardTypes[3]
        }`}
      >
        <EarningDetails earningDetails={creditCardContent?.earningPointsBlocks} />
      </Card>
    );
  });
};

const EarningDetails = ({ earningDetails }: DetailProps) => {
  return (
    <ul className="text-text mt-6 w-80 max-w-xs sm:w-auto">
      {earningDetails?.map((benefit, index) => (
        <li key={benefit?.heading ?? benefit?.description} className="mb-2 last:mb-0">
          {(index % 2) - 1 ? (
            <h5 className="text-primary mb-1 font-bold sm:mb-0">{benefit?.heading}</h5>
          ) : (
            <p className="mb-4">{benefit?.description}</p>
          )}
        </li>
      ))}
    </ul>
  );
};
