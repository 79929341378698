import { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { Accordion } from '@dx-ui/osc-accordion';
import { Markdown } from '@dx-ui/osc-markdown';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';

import { useLanguage } from '../../hooks';
import { getUrlHost } from '../../utils/helpers/urlBuilder';

import type { SupportedLanguage } from '@dx-ui/framework-i18n';
type Props = {
  cardName?: string | null;
  language?: SupportedLanguage;
  builtInBenefits?: {
    heading?: string | null;
  };
  breakDown?: {
    builtInBenefits?: string[];
    earningBenefits?: string[];
  };
  earningStructure?: {
    heading?: string;
    subheading?: string;
  };
};

const TierCallout = ({
  children,
  tier,
}: {
  children: React.ReactNode;
  tier: 'Silver' | 'Gold' | 'Diamond';
}) => {
  if (!children) return null;
  return (
    <>
      {tier && (tier === 'Silver' || tier === 'Gold' || tier === 'Diamond') ? (
        <ReactSVG
          aria-hidden
          className={cx('relative top-1 -ml-0.5 inline-block', {
            'text-tier-silver': tier === 'Silver',
            'text-tier-gold': tier === 'Gold',
            'text-tier-diamond': tier === 'Diamond',
          })}
          src="/dx_guests_app/images/banner.svg"
        />
      ) : null}
      <strong>{children}</strong>
    </>
  );
};

export const MoreDetails = ({ builtInBenefits, earningStructure, breakDown, cardName }: Props) => {
  const language = useLanguage();
  const [t] = useTranslation('credit-cards');
  const cardFeaturesContent = useMemo(
    () => (
      <>
        <h6 className="mb-2 font-black sm:mt-4 rtl:text-right">
          {builtInBenefits?.heading ? (
            <Markdown origin={getUrlHost()} language={language}>
              {builtInBenefits?.heading}
            </Markdown>
          ) : null}
        </h6>
        <ul className="text-text ml-4 list-outside list-disc">
          {breakDown?.builtInBenefits?.filter(Boolean).map((benefit) => {
            const tier = benefit.match(/\*\*(silver|gold|diamond)\*\*/i);
            return (
              <li key={benefit} className="w-fit [direction:ltr]">
                <Markdown
                  origin={getUrlHost()}
                  language={language}
                  options={{
                    overrides: {
                      strong: {
                        component: TierCallout,
                        props: { tier: tier?.[1] },
                      },
                    },
                  }}
                >
                  {benefit}
                </Markdown>
              </li>
            );
          })}
        </ul>

        <h6 className="mt-4 font-black rtl:text-right">
          {earningStructure?.heading ? (
            <Markdown origin={getUrlHost()} language={language}>
              {earningStructure?.heading}
            </Markdown>
          ) : null}
        </h6>
        <p className="text-text-alt mb-4 text-sm rtl:text-right">{earningStructure?.subheading}</p>
        <ul className="text-text ml-4 list-disc">
          {breakDown?.earningBenefits?.map((benefit) => (
            <li key={benefit} className="w-fit [direction:ltr]">
              {benefit ? (
                <Markdown
                  origin={getUrlHost()}
                  language={language}
                  options={{
                    overrides: {
                      strong: {
                        props: {
                          className: 'text-primary',
                        },
                      },
                    },
                  }}
                >
                  {benefit}
                </Markdown>
              ) : null}
            </li>
          ))}
        </ul>
      </>
    ),
    [
      builtInBenefits?.heading,
      language,
      breakDown?.builtInBenefits,
      breakDown?.earningBenefits,
      earningStructure?.heading,
      earningStructure?.subheading,
    ]
  );

  return (
    <>
      <Accordion
        className="accordion-single mx-auto mb-0 mt-3 w-32 justify-center sm:hidden"
        collapsedButtonLabel={t('moreDetailsOpen')}
        expandedButtonLabel={t('moreDetailsClose')}
        shouldBeInitiallyExpanded={false}
        expandedButtonAccessibleLabel={t('screenReader.moreDetailsExpanded', { cardName })}
        collapsedButtonAccessibleLabel={t('screenReader.moreDetailsCollapsed', { cardName })}
      >
        <section className="border-text-disabled mt-8 rounded-xl border px-3 py-4 text-left sm:mt-0 sm:rounded-none sm:border-none sm:p-0 rtl:text-center rtl:sm:text-right">
          {cardFeaturesContent}
        </section>
      </Accordion>
      <section className="border-text-disabled mt-8 hidden rounded-xl border px-3 py-4 text-left sm:mt-0 sm:block sm:rounded-none sm:border-none sm:p-0 rtl:text-center rtl:sm:text-right">
        {cardFeaturesContent}
      </section>
    </>
  );
};
