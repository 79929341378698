import { useTranslation } from 'next-i18next';

type Props = {
  className?: string;
  children: React.ReactNode;
};
export const CardGroup = ({ className, children }: Props) => {
  return (
    <div className={`border-text-alt relative rounded-xl border pb-9 ${className}`}>{children}</div>
  );
};
type LabelProps = {
  label: string;
  className?: string;
};
const Label = ({ className, label }: LabelProps) => {
  return (
    <h3
      className={`absolute -top-4 z-20 rounded-full px-4 py-2 font-bold ltr:-left-4 rtl:-right-4 ${className}`}
    >
      {label}
    </h3>
  );
};

export const PersonalLabel = () => {
  const [t] = useTranslation('credit-cards');
  return <Label label={t('personal')} className="bg-secondary text-text-inverse" />;
};

export const BusinessLabel = () => {
  const [t] = useTranslation('credit-cards');
  return <Label label={t('business')} className="bg-secondary text-text-inverse" />;
};
