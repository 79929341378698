import { Link } from '@dx-ui/osc-link';
import Image from 'next/legacy/image';

import { ImageLoader } from './helpers';
import type { OffersCreditCardGroup } from '@dx-ui/gql-types';

export const InternationalCards = ({
  internationalCardsContent,
}: {
  internationalCardsContent: OffersCreditCardGroup;
}) => {
  return (
    <section className="my-10 flex flex-col">
      <h2 className="font-headline text-hilton text-center text-2xl font-bold tracking-wide sm:text-left rtl:sm:text-right">
        {internationalCardsContent?.headline}
      </h2>
      <p className="mt-8 text-xl font-bold sm:my-2">{internationalCardsContent?.description}</p>
      <ul className="mt-4 grid gap-4 px-2 sm:mt-8 sm:grid-cols-3 sm:px-4">
        {internationalCardsContent &&
        internationalCardsContent?.items &&
        internationalCardsContent?.items.length > 0
          ? internationalCardsContent?.items?.map((cardDetails) => (
              <li
                key={`international-cards-${cardDetails?.cardName}`}
                className="border-primary flex items-center gap-4 rounded-lg border p-3"
              >
                <div className="shrink-0">
                  {cardDetails?.images?.length &&
                  cardDetails?.images[0]?.image?.image?.variants?.tabs[0]?.cdnLink ? (
                    <Image
                      src={cardDetails?.images[0]?.image?.image?.variants?.tabs[0]?.cdnLink}
                      alt={cardDetails?.images[0]?.altText || ''}
                      loader={ImageLoader}
                      width={100}
                      height={70}
                      aria-hidden={true}
                    />
                  ) : null}
                </div>
                <div>
                  <strong className="mb-0.5 block">{cardDetails?.cardName}</strong>
                  {cardDetails?.link && cardDetails?.link[0]?.linkUrl ? (
                    <Link
                      adaDescription={cardDetails?.link[0]?.adaDescription || ''}
                      isNewWindow={cardDetails?.link[0]?.isNewWindow || false}
                      url={cardDetails?.link[0]?.linkUrl}
                      className="text-text-alt no-underline"
                    >
                      {cardDetails?.link[0]?.linkLabel}
                    </Link>
                  ) : null}
                </div>
              </li>
            ))
          : null}
      </ul>
    </section>
  );
};
